import apiRequest from "../apiRequest";

export const index = ({ id, token }) =>
    apiRequest(`viewings/${id}/additional-status`, {
        method: 'GET',
        token,
    });

export const destroy = ({ id, additionalStatus, token }) =>
    apiRequest(`viewings/${id}/additional-status/${additionalStatus}`, {
        method: 'DELETE',
        token,
    });