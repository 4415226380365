import React from 'react';
import isNil from 'lodash/isNil';
import moment from 'moment';
import {
    getFullDateText,
    getTimeText,
} from '../../helpers/dates';

// Having reducerName in a different files allow the whole schema not to be compiled,
// before redux initializes, causing valuesFetcher to have an undefined fetcher callback.
// Please do not move
import { reducerName } from './variables';
import {Link} from "react-router-dom";
export { reducerName };

export const resourceDisplayName = 'Appointment';

export const resourcesDisplayName = 'Appointments';

export const resourceBaseRoute = 'appointments';

export const keyField = 'id';

export const nameField = 'id';

export const columns = [
    {
        dataField: 'id',
        text: 'Appointment ID',
        className: 'col-md-12',
    },
    {
        dataField: 'main_viewing_length',
        text: 'Type',
        className: 'col-md-12',
        formatter: (main_viewing_length) => {
            if (!isNil(main_viewing_length) && !isNil(main_viewing_length.viewing_type)) {
                return `${main_viewing_length.viewing_type.name} - ${main_viewing_length.description}`;
            } else if (!isNil(main_viewing_length) && isNil(main_viewing_length.viewing_type)) {
                return `${main_viewing_length.description}`;
            }
            return '';
        }
    },
    {
        dataField: 'resource',
        text: 'Recurring Appointment',
        className: 'col-md-12',
        formatter: resource => (
            (!isNil(resource.viewing_recurring) || !isNil(resource.parent_viewing_recurring))
                ? "Yes" : "No"
        )
    },
    {
        text: 'Your Reference',
        dataField: 'reference',
        className: 'col-md-12 col-12',
    },
    {
        dataField: 'property.postcode',
        text: 'Postcode',
        className: 'col-md-4 col-12',
        formatter: (value) => value ? value.toUpperCase() : 'N/A',
    },
    {
        dataField: 'property.full_address',
        text: 'Address',
        className: 'col-md-12 col-12',
    },
    {
        dataField: 'preferred_date_1',
        text: 'Date/Time',
        className: 'col-md-12 col-12',
        formatter: (preferred_date_1) => preferred_date_1 ? `${getFullDateText(preferred_date_1)} ${getTimeText(preferred_date_1)}` : 'N/A',
    },
    {
        dataField: 'created',
        text: 'Submitted at',
        className: 'col-md-12 col-12',
        formatter: (created) => created ? `${getFullDateText(created)} ${getTimeText(created)}` : 'N/A',
    },
    {
        dataField: 'client_status',
        text: 'Status',
        className: 'col-md-12 col-12',
    },
    {
        dataField: 'user.full_name',
        text: 'Booked By',
        className: 'col-md-12 col-12',
    },
    {
        dataField: 'resource',
        textFormatter: (resource) =>  (
            resource.confirmed_appointment
            && resource.confirmed_appointment.viewber
        ) ? 'Viewber Info' : '',
        className: 'col-md-12 col-12',
        formatter: (resource) => {
            if (!resource.confirmed_appointment || !resource.confirmed_appointment.viewber) {
                return null;
            }

            const viewing = resource;
            const viewber = resource.confirmed_appointment.viewber;
            let viewberFullNameAndMaybePhone = 'TBD';
            let viewberMaskedPhoneInformationBody = null;

            // Set the viewber name.
            viewberFullNameAndMaybePhone = viewing.requires_key_delivery_by_post || viewing.user.viewber_full_name_enabled
                ? viewber.full_name
                : viewber.partial_name;

            // If the viewber phone_type is default then append the phone to the
            // viewber name.
            if (viewber.phone_type === 'default') {
                viewberFullNameAndMaybePhone += ' ' + viewber.phone;
            }

            // If the viewber phone_type is masked then build the masked
            // phone information.
            if (viewing.confirmed_appointment.viewber.phone_type === 'masked') {
                
                let hoursSinceFeedback = 0;
                if (viewing.confirmed_appointment.feedback_submitted_at !== null) {
                    const feedbackSubmittedAt = moment(viewing.confirmed_appointment.feedback_submitted_at);
                    hoursSinceFeedback = moment().diff(feedbackSubmittedAt, 'hours');
                }

                // If the viewing has status 1 then build the viewber
                // masked phone information body.
                if (viewing.status === 1
                    || (
                        viewing.status === 3
                        && viewing.confirmed_appointment.feedback_sent === 1
                        && hoursSinceFeedback <= 24
                    )
                ) {
                    viewberMaskedPhoneInformationBody = (
                        <p className='m-0 font-weight-semibold'>
                            {viewing.user.client?.twilio_pin
                                ? (
                                    <span>
                                        Please call <strong>{viewber.phone} </strong>
                                        and enter the Pin number <strong> {viewing.user.client?.twilio_pin?.pin || ''} </strong>
                                        and the appointment id <strong>{viewing.id}</strong> to contact the viewber.
                                    </span>
                                )                                
                                : (
                                    <Link to="/me/phone-pin">
                                        Please generate a phone pin to be able to call the Viewber
                                    </Link>
                                )
                            }
                        </p>
                    )
                }
            }

            return (
                <React.Fragment>
                    <span>{viewberFullNameAndMaybePhone}</span>
                    {viewberMaskedPhoneInformationBody}
                </React.Fragment>
            )
        },
    },
    {
        dataField: 'cancelled',
        className: 'col-12',
        formatter: cancelled => {
            if (!cancelled) {
                return null;
            }
            return <span className='badge badge-danger'>Cancelled</span>
        },
    },
];

export const sortingOptions = [
    {
        display_name: 'Appointment ID',
        name: 'id',
        direction: 'desc',
    },
    {
        display_name: 'Appointment ID',
        name: 'id',
        direction: 'asc',
    },
    {
        display_name: 'Date',
        name: 'preferred_date_1',
        direction: 'desc',
    },
    {
        display_name: 'Date',
        name: 'preferred_date_1',
        direction: 'asc',
    },
    {
        display_name: 'Your Reference',
        name: 'reference',
        direction: 'asc',
    },
    {
        display_name: 'Your Reference',
        name: 'reference',
        direction: 'desc',
    },
];

export const filters = [
    'status'
]

export const defaultSortingOption = { ...sortingOptions[0] };

const searchColumns = [
    'Postcode',
    'Appointment ID',
    'Reference'
];

export const searchTextInputPlaceholder = `Search by ${searchColumns.join(', or ')}`;

export const pageSize = 10;