import * as viewingAdditionalStatusAPI from '../additionalStatus';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import {useQuery} from '@tanstack/react-query';

const viewingAdditionalStatusQueryKey = (
    viewingId,
    token
) => [token, 'viewings', viewingId, 'additional-status', 'index'];

export const useViewingAdditionalStatus = (viewingId, token, options) => {
    const queryKey = viewingAdditionalStatusQueryKey(viewingId, token);
    const reactQueryOptions = parseReactQueryOptions(options);

  const {
    data,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useQuery(
    queryKey,
    () => new Promise((resolve, reject) => {
      viewingAdditionalStatusAPI.index({id: viewingId, token})
        .then(res => resolve(res.data.data))
        .catch(reject);
    }),
    reactQueryOptions
  );

  return {
    additionalStatus: data,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  }
}

const parseReactQueryOptions = params => {
  if (isObject(get(params, 'reactQueryOptions'))) {
    return params.reactQueryOptions;
  }
  return {
    staleTime: 5 * 60 * 1000
  };
}