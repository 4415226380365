import * as recurringViewingsAPI from '../index';
import has from 'lodash/has';
import {useQuery} from '@tanstack/react-query'
import { useState } from 'react';

const paginatedRecurringViewingRecurrencesQueryKey = (id, token, params) => [token, 'recurringViewings', id, 'recurrences', 'paginate', params];

export const usePaginatedRecurringViewingRecurrences = (id, token, params) => {
    const [page, setPage] = useState();

    const requestParams = buildRequestParams(page, params);
    const reactQueryOptions = buildReactQueryOptions(params);

    const {
        data,
        isLoading,
        isError,
        error,
        refetch,
    } = useQuery(
        paginatedRecurringViewingRecurrencesQueryKey(id, token, requestParams),
        () => new Promise((resolve, reject) => {
        recurringViewingsAPI.paginateRecurrences({id, token, ...requestParams})
            .then(res => resolve(res.data))
            .catch(reject);
        }),
        reactQueryOptions
    );

    return {
        viewings: data?.data,
        links: data?.links,
        meta: data?.meta,
        isLoading,
        isError,
        error,
        refetch,
        setPage: page => setPage(page),
    }
}

const buildRequestParams = (page, params) => {
    const requestParams = {};

    if (page) {
        requestParams.page = page;
    }
    if (has(params, 'pageSize')) {
        requestParams.page_size = params.pageSize;
    }
    if (has(params, 'q')) {
        requestParams.q = params.q;
    }
    if (has(params, 'sortName')) {
        requestParams.sort_name = params.sortName;
    }
    if (has(params, 'sortDirection')) {
        requestParams.sort_direction = params.sortDirection;
    }

    return requestParams;
}

const buildReactQueryOptions = (options) => {
    const reactQueryOptions = {
        staleTime: 1 * 60 * 1000
    };

    if (has(options, 'refetchOnMount')) {
        reactQueryOptions.refetchOnMount = options.refetchOnMount;
    }
    if (has(options, 'refetchOnReconnect')) {
        reactQueryOptions.refetchOnReconnect = options.refetchOnReconnect;
    }
    if (has(options, 'refetchOnWindowFocus')) {
        reactQueryOptions.refetchOnWindowFocus = options.refetchOnWindowFocus;
    }
    if (has(options, 'staleTime')) {
        reactQueryOptions.staleTime = options.staleTime;
    }

    return reactQueryOptions;
}