import * as recurringViewingsAPI from '../index';
import get from 'lodash/get';
import {useQuery, useMutation, useQueryClient} from '@tanstack/react-query'

const showRecurringViewingQueryKey = recurId => ['recurringViewing', recurId, 'show'];

export const useRecurringViewing = (
    recurId,
    token,
    options
) => {
  const queryClient = useQueryClient();

  const {
    data,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery(
      showRecurringViewingQueryKey(recurId),
    () => new Promise((resolve, reject) => {
      recurringViewingsAPI.find({
        id: recurId,
        token,
        params: {
          expand: get(options, 'expand')
        }
      })
        .then(res => resolve(res.data.resource))
        .catch(reject);
    })
  );

  const {
    mutate: update,
    isLoading: isUpdating,
    isError: updateIsError,
    error: updateError
  } = useMutation(
      ({id, data}) => new Promise((resolve, reject) => {
        recurringViewingsAPI
            .update({ id, token, ...data })
            .then(response => resolve({
              recurringViewing: response.data.resource,
            }))
            .catch(reject);
      }),
      {
        onSuccess: (data) => {
          const { recurringViewing } = data;

          queryClient.setQueryData(showRecurringViewingQueryKey(recurId), oldData => ({
            ...oldData,
            ...recurringViewing
          }));

        }
      }
  );

  return {
    recurringViewing: data,
    isLoading,
    isError,
    error,
    refetch,
    actions: {
      update: {
        handle: (id, data, mutationOptions) => update({id, token, data}, mutationOptions),
        isLoading: isUpdating,
        isError: updateIsError,
        error: updateError
      },
    }
  }
}